import React from "react"
import { Link } from "gatsby"
import { Button, Typography } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"

const { Title } = Typography
const icon = require('../images/success.svg');

const ThankYouPage = () => (
  <Layout className="thank-you">
    <SEO title="Thank You" />

    <Title className="page-title">
      Thank You! We received your service request.
    </Title>

    <div className="thank-you__content">
      <p>
        One of our service specialists will contact you within 1 business day to confirm all the details and get your cleaning project going. 
        
      </p>
      <p>
        In the meantime, if any urgent questions come up, don’t hesitate to call us at: <a href="tel:+13038483884"> (303) 848 3884 </a>
      </p>
      

      <p>
        Have a great day!
      </p>
      <div>
      <img src={icon} alt='Success' />
      </div>

      <Button type="primary" className="primary-cta">
        <Link to="/">Okay, take me back</Link>
      </Button>
    </div>
  </Layout>
)

export default ThankYouPage
